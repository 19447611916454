import { callWithNuxt } from 'nuxt/app'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $localePath } = useNuxtApp()
  const api = useApi()
  const isLoggedIn = await api.validateUser().catch(() => {
    // Just ignore this part - navigateTo() seems to be broken with catch statements
    // https://github.com/nuxt/nuxt/issues/14269#issuecomment-1397352832
    // return navigateTo(localePath('/'))
  })

  if (!isLoggedIn) {
    console.log('User is not logged in, redirecting')
    return await navigateTo($localePath('/'))
  }
})
